import React from 'react';
import Container from 'react-bootstrap/Container';

export default function Services() {
    return(
      <Container id='services'>
          <h2>Services</h2>
          <div className='blue-line'></div>
          <div className='spacing'></div>
          <p>I tutor Preschool math, Elementary school math, Pre-algebra, Algebra I, Algebra II. I prefer to start with students in preschool, elementary, or middle school– that way I can fill in any gaps in knowledge before they reach higher-level mathematics.</p>
          <br></br>
          <h4>Other Services Offered</h4>
          <br></br>
            <p>Organizational skills: If needed, I help the student learn how to use a planner, turn in assignments on time, develop study plans for regular assessments and exams, etc. Some students need to be taught these skills once, whereas others need ongoing support to stay organized with these difficult executive functioning skills.</p>
            <p>While my primary focus is math, I tutor most subjects. Please discuss with me to see if I can help in addition to math tutoring.</p>
            
      </Container>
    );
  }