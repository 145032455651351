import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Banner() {
  return(
    <div id='home' className='banner'>
      <Container className='inside-banner'>
        <Row className='align-items-center'>
            <Col md={12} className='mb-4'>
              <h3>Private Math Tutoring Services</h3>
            </Col>
        </Row>
    </Container>
    </div>
  );
}