import React from 'react';
import Container from 'react-bootstrap/Container';

export default function FAQ() {
    return(
      <Container id='faq'>
          <h2>FAQ</h2>
          <div className='blue-line'></div>
          <div className='spacing'></div>
          <h4>What students do I work with?</h4>
          <p>I work with public, private, and homeschool students of all ages. I mostly work with neurodiverse students (e.g., ADHD, Autism).</p>
          
          <br></br>
          
          <h4>Where do I tutor? Do I offer online lessons? In-person lessons?</h4>
          <p>I conduct tutoring sessions over Zoom and also offer in-person sessions at Ashburn Library.</p>
          
          <br></br>

          <h4>How much do I charge?</h4>
          <p>I charge $150/hour. I increase my rate if I need to drive anywhere other than Ashburn Library depending on the time and distance.</p>
      </Container>
    );
  }