import Carousel from 'react-bootstrap/Carousel';

export default function ReviewCarousel() {
  return(
    <Carousel className='carousel' data-bs-theme="dark" controls={true} indicators={false} interval={4000}>
        <Carousel.Item className='carousel-item'>
            <h3>Trinity J.</h3>
            {[...Array(5)].map((_, index) => (
            <img key={index} src={"stars.svg"} alt={`Star ${index + 1}`} />
            ))}
            <p>I am so grateful for Daniellle!! We tried other tutoring places, and they seemed to be a temporary fix.  My daughter was really struggling in math. Danielle has a way of explaining and breaking down math concepts so that she  is able to understand. Danielle is patient, kind, and so attentive to my daughter’s learning style.  I am thrilled to see a confidence in my daughter that was not ever there before.  There are no more tears with math homework, or preparation for test. Danielle has changed learning completely for my daughter. Danielle takes time and care. The summaries she provides after each session are so helpful!  If you are looking for a person who truly cares about your child’s learning success, then look no farther than Danielle.</p>
        </Carousel.Item>
        <Carousel.Item className='carousel-item'>
            <h3>Alicia L.</h3>
            {[...Array(5)].map((_, index) => (
            <img key={index} src={"stars.svg"} alt={`Star ${index + 1}`} />
            ))}
            <p>Danielle has been so helpful with building a deep understanding of math for my daughter. As a homeschooler, I was worried about not being able to fully explain concepts well and develop her math skills up to grade level. We had tried outside math programs however they were not helpful. Danielle took the time to sit and truly learn Alia’s learning style and tailored the work towards how she learns best. Now Alia is doing much better and is showing a stronger understanding than ever before.  We look forward to continuing with Danielle and I will soon start  my kindergartener in the fall with her as well. Highly recommend!</p>
        </Carousel.Item>
        <Carousel.Item className='carousel-item'>
            <h3>Elizabeth G.</h3>
            {[...Array(5)].map((_, index) => (
            <img key={index} src={"stars.svg"} alt={`Star ${index + 1}`} />
            ))}
            <p>We are incredibly grateful for the exceptional tutoring provided by Danielle. She has been an invaluable resource in helping our daughter excel in math.  Danielle's teaching style is both engaging and effective. She can break down complex math concepts into understandable parts, making the learning process enjoyable and accessible. Our daughter's confidence in math has grown significantly under Danielle's guidance.

What truly sets Danielle apart is her genuine passion for teaching and her unwavering patience. She goes the extra mile to ensure our daughter understands the material and feels comfortable asking questions. Danielle's dedication to our daughter's success is evident in every session.

Danielle is incredibly accommodating when it comes to scheduling. She understands the demands of a student's life and is always willing to adjust sessions to fit our needs.

Danielle is a top-notch math tutor, and we feel fortunate to have found her. Her expertise, dedication, and caring approach have made a world of difference in our daughter's academic journey. We highly recommend Danielle to anyone seeking exceptional math tutoring.</p>
        </Carousel.Item>
        <Carousel.Item className='carousel-item'>
            <h3>Ivonne M.</h3>
            {[...Array(5)].map((_, index) => (
            <img key={index} src={"stars.svg"} alt={`Star ${index + 1}`} />
            ))}
            <p>Danielle has been tutoring my son during the school year in Algebra II and Pre-Calculus over the summer. His grades have improved in a short amount of time and his confidence in math continues to grow. He used to dread math but Danielle has made the subject engaging and fun for him. I highly recommend Danielle for math tutoring!</p>
        </Carousel.Item>
        <Carousel.Item className='carousel-item'>
            <h3>Linda M.</h3>
            {[...Array(5)].map((_, index) => (
            <img key={index} src={"stars.svg"} alt={`Star ${index + 1}`} />
            ))}
            <p>Danielle has been tutoring my daughter in reading. She has been a blessing to our family, she has taken the time to understand how her pupil learns and our child is thriving under her tutelage. I can’t say enough good things about her skills and abilities as a teacher, we are so grateful for her expertise and enthusiasm, my daughter looks forward to her sessions.</p>
        </Carousel.Item>
    </Carousel>
  );
}