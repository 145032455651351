import React from 'react';
import { BsEnvelope, BsLinkedin } from "react-icons/bs";

export default function FooterComponent() {
  let currentYear = new Date().getFullYear();
  return (
    <footer className='site-footer'>
      <div className='mb-2'>
        <a className='footer-link' href='mailto:daniellegraetutoringllc@gmail.com'><BsEnvelope /></a>
        <a className='footer-link' target='_blank' href='https://www.linkedin.com/in/danielle-grae/' rel="noreferrer"><BsLinkedin /></a>
      </div>
      <small>&copy; Copyright {currentYear}, Danielle Grae Tutoring, LLC</small>
    </footer>
  );
}