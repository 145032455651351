import React from 'react';
import Container from 'react-bootstrap/Container';

export default function Resume() {
  return(
    <Container id='resume'>
        <h2>Education & Experience</h2>
        <div className='blue-line'></div>
        <div className='spacing'></div>
        <p>I graduated <i>cum laude</i> from The College of William & Mary with a Bachelor of Arts in French and a minor in Mathematics. I obtained a <strong>4.0 GPA</strong> in graduate coursework at William & Mary’s School of Education with a concentration in Elementary School Curriculum & Instruction and a certificate in English as a Second Language (ESL). I work full-time as a math tutor for students with ADHD, autism, and other students who struggle to learn in the traditional classroom.
        </p>
    </Container>
  );
}