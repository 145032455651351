import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

export default function Contact() {
    return(
      <Container id='contact'>
          <h2>Contact Me</h2>
          <div className='blue-line'></div>
          <div className='spacing'></div>
          <h4 className='mb-4'>Click Below to Schedule an Appointment</h4>
          <a href='https://docs.google.com/forms/d/e/1FAIpQLSfhpyBNrL7r9GWCLS0-AwdbCivFb-O9CaVDHBZ1NjV3sBxFNQ/viewform?usp=sf_link'><Button size="lg" variant="primary">Book an Appointment</Button></a>
      </Container>
    );
  }