import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function NavbarComponent() {
  return(
    <Navbar sticky='top' className="navbar-nav py-3 gap-3 px-3" collapseOnSelect expand="lg" bg="primary" variant="dark">
      <Container>
        <Navbar.Brand href="#home">Danielle Grae Tutoring</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav>
            <Nav.Link className="nav-link" href="#resume">Education & Experience</Nav.Link>
            <Nav.Link className="nav-link" href="#philosophy">Philosophy</Nav.Link>
            <Nav.Link className="nav-link" href="#mission">Mission</Nav.Link>
            <Nav.Link className="nav-link" href="#services">Services</Nav.Link>
            <Nav.Link className="nav-link" href="#faq">FAQ</Nav.Link>
            <Nav.Link className="nav-link" href="#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;