import React from 'react';
import Container from 'react-bootstrap/Container';

export default function Mission() {
  return(
    <Container id='mission'>
        <h2>Mission</h2>
        <div className='blue-line'></div>
        <div className='spacing'></div>
        <div>
          <p>
          My mission is to <strong>empower neurodiverse students to learn math and succeed in school</strong>. As a neurodiverse person myself, I struggled to learn in the classroom and had no choice but to figure out how to teach myself entire curriculums. I succeeded through a top-tier college and graduate school, mastering the material and achieving the highest grades. I use the ability to break down concepts and teach myself anything to become the tutor I wish I had as a student– <strong>someone who knows how to teach math in a way that makes sense to the neurodivergent brain</strong>. 
          </p>
        </div>
    </Container>
  );
}