import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsBox, BsPencil, BsLightbulb, BsStar } from 'react-icons/bs';

export default function Skills() {
  return(
    <Container id='philosophy'>
        <h2>Teaching Philosophy</h2>
        <div className='blue-line'></div>
        <div className='spacing'></div>
        <p>Every student is unique in their learning. That’s why I structure my lessons based on the student’s needs.</p>
        <br></br>
        <Row>
            <Col md={6} className=''>
              <h4 className='skills-icon'><BsBox /></h4>
              <h4>Detail and Compartmentalize</h4>
              <p>Break down complex concepts into smaller, more manageable parts and provide clear, systematic, and detailed problem-solving approaches.</p>
            </Col>
            <Col md={6} className=''>
              <h4 className='skills-icon'><BsPencil  /></h4>
              <h4>Test-Taking Skills</h4>
              <p>Some students need more help with “test-taking skills” to avoid careless errors. I help ensure students focus on the fine details and make fewer mistakes on their exams.</p>
            </Col>
        </Row>
        <Row>
            <Col md={6} className=''>
              <h4 className='skills-icon'><BsLightbulb  /></h4>
              <h4>Structure and Clarity</h4>
              <p>I offer highly structured sessions to students who are often labeled “troublemakers.” By providing the utmost clarity, predictability, and leaving no room for misunderstandings, I am able to have sessions free of behavioral problems with most students.</p>
            </Col>
            <Col md={6} className=''>
              <h4 className='skills-icon'><BsStar /></h4>
              <h4>Special Interests</h4>
              <p>I also incorporate the students' interests in teaching concepts. For example, if a student loves Minecraft, I can explain and create math problems based on Minecraft. This strategy helps students develop a love for math and learning.</p>
            </Col>
        </Row>
    </Container>
  );
}